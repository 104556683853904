//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across The Project Template.
@gray-dark: 		    #333333;
@gray:            	    #777777;
@gray-light:            #999999;
@gray-lighter:          #cdcdcd;
@white:                 #ffffff;
@black:                 #000000;
@gray-text:             #41505a;

//** Base Colors.
@base-color:            #5aaf2d;
@base-color-hover:      #53a627;
//** Second Colors.
@second-color:            #009bd7;
@second-color-hover:      #0090c8;
//** Background color for `<body>`.
@body-bg:               #ffffff;
//** Global text color on `<body>`.
@text-color:            @gray;
//** Global textual link color.
@link-color:            @base-color;
//** Link hover color set via `darken()` function.
@link-color-hover:      @base-color-hover;
//** Link hover decoration.
@link-hover-decoration: underline;
//** Dark Header Top and Header
@dark-header:                   #394245;
@dark-header-opacity-95:        rgba(57, 66, 69, 0.95);
@dark-header-opacity-90:        rgba(57, 66, 69, 0.9);
@dark-header-opacity-80:        rgba(57, 66, 69, 0.8);
@dark-header-opacity-60:        rgba(57, 66, 69, 0.6);
@dark-header-top:               #2e3537;
@dark-header-top-opacity-60:    rgba(37, 42, 44, 0.5);
@dark-header-top-border:        #252a2c;
@dark-navigation-item-border:   rgba(57, 66, 69, 0.8);

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-roboto:     'Roboto', 'Microsoft JhengHei', sans-serif;
@font-family-lato:       'Microsoft JhengHei', 'Lato', sans-serif;
@font-family-helvetica:  'Microsoft JhengHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-raleway:    'Microsoft JhengHei', 'Raleway', sans-serif;
@font-family-pt-serif:   'Microsoft JhengHei', 'PT Serif', serif;
@font-family-pacifico:   'Microsoft JhengHei', 'Pacifico', cursive, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-roboto-slab:'Microsoft JhengHei', 'Roboto Slab', serif;
@font-family-base:        @font-family-roboto;

@font-size-base:          16px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.22666)); // ~33px
@font-size-h1-large:            floor((@font-size-base * 3)); // 45px
@font-size-h2:            floor((@font-size-base * 1.86667)); // ~28px
@font-size-h3:            ceil((@font-size-base * 1.6)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~19px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~13px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.466666666667; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    @font-family-raleway;
@headings-font-weight:    400;
@headings-line-height:    1.2;
@headings-color:          inherit;

//== Social Icons Colors
//
@twitter: 			  #55acee;
@skype: 				  #00aff0;
@facebook:                 #3b5998;
@linkedin:                 #0976b4;
@googleplus:               #dd4b39;
@youtube:                  #b31217;
@flickr:                   #ff0084;
@pinterest:                #cb2027;
@instagram:                #517fa4;
@vimeo:                    #aad450;
@tumblr:                   #32506d;
@soundcloud:               #ff3a00;
@foursquare:               #0072b1;
@dribbble:                 #ea4c89;
@behance:                  #053eff;
@vine:                     #00a478;
@stumbleupon:              #f74425;
@xing:              	  #126567;
@line:              	  #20c92f;