// Core variables and mixins
@import "variables.less";
@import "mixins.less";

/* 1 Typography
----------------------------------------------------------------------------- */
/*Fonts
---------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.navbar .navbar-nav {
	font-family: @font-family-raleway;
}
body {
	font-family: @font-family-base;
}
.logo-font {
	font-family: @font-family-pacifico;
}
/*Typography
---------------------------------- */
html {
	height: 100%;
}
body {
	font-size: @font-size-base;
	line-height: @line-height-base;
	color: @gray-text;
	background-color: @body-bg;
	font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: @gray-dark;
	line-height: @headings-line-height;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: @gray-dark;
}
.dark {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #ffffff;
	}
	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a {
		color: #ffffff;
	}
}
.dark .footer {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: @white;
	}
	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a {
		color: @white;
	}
}
h1 {
	font-size: @font-size-h1;
	font-weight: 700;
}
h1.logo-font {
	text-transform: none;
	font-weight: 300;
	font-size: 50px;
}
h1.large {
	font-size: @font-size-h1-large;
	font-weight: 300;
	letter-spacing: 0.1em;
}
h2 {
	font-size: @font-size-h2;
	margin-bottom: 15px;
	text-transform: uppercase;
}
h2.logo-font {
	text-transform: none;
}
h3 {
	font-size: @font-size-h3;
}
h4 {
	font-size: @font-size-h4;
}
h5 {
	font-size: @font-size-h5;
	text-transform: uppercase;
	font-weight: 700;
}
h6 {
	font-size: @font-size-h6;
	font-weight: 700;
}
/* Small devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
	.jumbotron h1 {
		font-size: 130px;
	}
}
/* Small devices (tablets, phones less than 767px) */
@media screen and (max-width: 767px) {
	.jumbotron h1 {
		font-size: 60px;
	}
}
.page-title {
	margin-top: 0;
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
	.title {
		margin-top: 0;
	}
}
.sidebar .title {
	margin-top: 3px;
}
p {
	margin-bottom: 15px;
}
p.large {
	font-size: 18px;
	line-height: 1.4;
	margin-bottom: 30px;
}
a {
	color: @base-color;
	.transition(all 0.2s ease-in-out);
}
a:hover {
	color: @base-color-hover;
	text-decoration: none;
}
a:focus,
a:active {
	outline: none;
	color: @base-color-hover;
}
.link-dark {
	color: @gray-dark;
	text-decoration: none!important;
}
.link-dark:hover {
	color: @gray-dark;
	text-decoration: underline!important;
}
.dark .footer {
	.link-dark {
		color: @gray;
		text-decoration: none!important;
	}
	.link-dark:hover {
		color: @gray;
		text-decoration: underline!important;
	}
}
.link-light {
	color: @white;
	text-decoration: none!important;
}
.link-light:hover {
	color: @white;
	text-decoration: underline!important;
}
blockquote {
	border-left: none;
	display: inline-block;
	margin: 20px auto 20px;
	font-size: 16px;
	position: relative;
	padding: 10px 25px;
}
blockquote:after {
	content: "“";
	width: 25px;
	height: 25px;
	line-height: 36px;
	font-size: 36px;
	font-family: @font-family-pt-serif;
	position: absolute;
	top: 12px;
	left: 0px;
	color: @gray-lighter;
}
.blockquote:before {
	font-family: @font-family-pt-serif;
	content: "“";
	font-size: 40px;
}
blockquote.inline {
	padding: 0;
}
blockquote.inline p {
	width: 60%;
	display: inline-block;
	margin: 0;
}
blockquote.inline footer {
	width: 37%;
	display: inline-block;
	padding-left: 5px;
}
blockquote.inline:after {
	top: 0;
}
mark,
.mark {
	background-color: @base-color;
	color: #ffffff;
}
pre {
	padding: 20px;
	.border-radius(0px);
}
.text-muted {
	color: #999999;
}
.text-default {
	color: @white;
}
.text-white {
	color: #ffffff!important;
}
.well {
	.border-radius(0px);
	background-color: #f3f3f3;
	.box-shadow(inset 0 1px 2px rgba(0, 0, 0, .05));
}
.dark hr {
	border-color: @gray-dark;
}
